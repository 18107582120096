import * as basedURL from '../../apis/baseURL';

import React, { useState } from 'react';

import Icon from '../Icon';
import Modal from './Modal';
import PropTypes from 'prop-types';
import arrowIcon from '../../images/workshop/rightArrowIcon.png';
import haftCircle from '../../images/workshop/HaftCircle.png';
import styled from 'styled-components';

const WorkShopComp = ({ workshops }) => {
  const [showFormModal, setFormModal] = useState(false);
  const [sendOK, setSendok] = useState(null);
  const [fullname, setFullname] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [facebook, setFacebook] = useState('');
  const [subject, setSubject] = useState('');
  const [errorfullname, setErrorfullname] = useState('');
  const [errortelephone, setErrortelephone] = useState('');
  const [erroremail, setErroremail] = useState('');
  const [wsData, setWsdata] = useState({});
  if (!workshops) return null;
  const { wshops } = workshops;
  const workShophandle = id => {
    const selectedWS = wshops.filter(ws => ws.id.includes(id));
    setWsdata(selectedWS);
    setFormModal(true);
  };
  const handleSendingData = data => {
    fetch(`${basedURL.LMS}/storage`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: data
    }).then(response => {
      if (response.status === 200)
        setSendok('DOL đã nhận được thông tin của bạn! Cám ơn bạn đã quan tâm');
    });
  };
  const formChangeHandler = event => {
    const nam = event.target.name;
    const val = event.target.value;
    if (nam === 'fullname') {
      if (val === '') {
        setErrorfullname('Yêu cầu nhập Họ và Tên');
      } else {
        setErrorfullname('');
        setFullname({ [nam]: val });
      }
    }

    if (nam === 'telephone') {
      if (val === '') {
        setErrortelephone('Yêu cầu nhập số điện thoai');
      } else {
        setErrortelephone('');
        setTelephone({ [nam]: val });
      }
    }

    if (nam === 'email') {
      if (val === '') {
        setErroremail('Yêu cầu nhập email');
      } else {
        setErroremail('');
        setEmail({ [nam]: val });
      }
    }
    if (nam === 'facebook') {
      if (val !== '') {
        setFacebook({ [nam]: val });
      }
    }
    if (nam === 'subject') {
      if (val !== '') {
        setSubject({ [nam]: val });
      }
    }
  };
  const formSubmitHandler = () => {
    if (fullname === '') {
      setErrorfullname('Yêu cầu nhập Họ và Tên');
    } else {
      setErrorfullname('');
    }

    if (telephone === '') {
      setErrortelephone('Yêu cầu nhập số điện thoai');
    } else {
      setErrortelephone('');
    }

    if (email === '') {
      setErroremail('Yêu cầu nhập email');
    } else {
      setErroremail('');
    }
    if (fullname !== '' && telephone !== '' && email !== '') {
      const frmData = {
        email,
        fullname,
        phone: telephone,
        facebook,
        subject,
        purpose: subject
      };
      handleSendingData(JSON.stringify(frmData));
    }
  };

  return (
    <WorkshopWrapper>
      <SectionTitle>Chọn chủ đề bạn muốn tham dự</SectionTitle>
      <WorshopRow>
        {wshops.map(ws => (
          <WorshopItemWrapper
            key={ws.id}
            onClick={() => {
              workShophandle(ws.id);
            }}
          >
            <WorshopItem>
              <WorkshopTitle>{ws.name}</WorkshopTitle>
              <WorkshopTime>{ws.time}</WorkshopTime>
              <WorkshopTime>{ws.date}</WorkshopTime>
              <WorkshopLevel>{ws.level} </WorkshopLevel>
              <ArrowIcon />
            </WorshopItem>
          </WorshopItemWrapper>
        ))}
      </WorshopRow>
      <hr />
      <FormModal show={showFormModal} showCloseIcon="true">
        <ModalContent>
          <Content>
            {wsData.length && (
              <DetailContent>
                <FormTitle>{wsData[0].name}</FormTitle>
                <Seperator />
                <Detail>{wsData[0].detail}</Detail>
                <DetailBlock>
                  <WorkshopTime>{wsData[0].time}</WorkshopTime>
                  <WorkshopTime>{wsData[0].date}</WorkshopTime>
                  <WorkshopLevel>{wsData[0].level} </WorkshopLevel>
                </DetailBlock>
              </DetailContent>
            )}
          </Content>
          <Register>
            <FormHeading>
              <Circle />
              <FormTitle>Đăng ký tham gia</FormTitle>
            </FormHeading>
            <form onSubmit={formSubmitHandler} method="post" action="#">
              <MessageBoard>
                {errorfullname && <span>{errorfullname}</span>}
                {errortelephone && <span>{errortelephone}</span>}
                {erroremail && <span>{erroremail}</span>}
                {sendOK && <span>{sendOK}</span>}
              </MessageBoard>
              <p>Họ và tên (*)</p>
              <p>
                <input
                  type="text"
                  name="fullname"
                  onChange={formChangeHandler}
                />
              </p>
              <p>Số điện thoại (*)</p>
              <p>
                <input
                  type="text"
                  name="telephone"
                  onChange={formChangeHandler}
                />
              </p>
              <p>Email (*)</p>
              <p>
                <input type="text" name="email" onChange={formChangeHandler} />
              </p>
              <p>Link Facebook cá nhân của bạn là ...</p>
              <p>
                <input
                  type="text"
                  name="facebook"
                  onChange={formChangeHandler}
                />
              </p>
              <p>Bạn đăng ký tham dự chủ đề nào? (*)</p>
              <p>
                <SelectCustom name="subject" onChange={formChangeHandler}>
                  {wsData.length && (
                    <option value={wsData[0].name}>{wsData[0].name}</option>
                  )}
                  {workshops.wshops.map(ws => (
                    <option key={ws.name} value={ws.name}>
                      {ws.name}
                    </option>
                  ))}
                </SelectCustom>
              </p>
              <p>
                <button type="button" onClick={formSubmitHandler}>
                  Đăng ký
                </button>
              </p>
            </form>
          </Register>
          <CloseModal
            onClick={() => {
              setFormModal(false);
            }}
          >
            <Icon type="CloseIcon" />
          </CloseModal>
        </ModalContent>
      </FormModal>
      <DotCircleLeft />
      <WSSharp />
    </WorkshopWrapper>
  );
};
export default WorkShopComp;
WorkShopComp.propTypes = {
  workshops: PropTypes.shape({
    wshops: PropTypes.arrayOf(PropTypes.shape({}))
  })
};
const WorkshopWrapper = styled.div`
  padding: 20px;
  position: relative;
  @media screen and (min-width: 769px) {
    padding: 60px 150px;
  }
`;
const WorshopRow = styled.div`
  @media screen and (min-width: 769px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

const WorshopItemWrapper = styled.div`
  @media screen and (min-width: 769px) {
    width: 31%;
    margin-right: 2%;
  }
`;
const WorkshopTitle = styled.div`
  font-weight: 700;
  width: 70%;
  @media screen and (min-width: 769px) {
    font-size: 24px;
    color: #000;
    width: 100%;
  }
`;
const WorkshopTime = styled.div`
  margin: 10px 0;
  @media screen and (min-width: 769px) {
    font-size: 16px;
    color: #2d3742;
  }
`;
const WorkshopLevel = styled.div`
  margin: 10px 0;
  @media screen and (min-width: 769px) {
    font-size: 16px;
    color: #7a869a;
  }
`;
const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding: 30px;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    padding: 50px;
  }
`;
const ArrowIcon = styled.div`
  background-image: url(${arrowIcon});
  width: 29px;
  height: 22px;
  background-size: 100%;
  position: absolute;
  top: 15px;
  right: 10px;
  @media screen and (min-width: 769px) {
    position: relative;
    transition: transform 300ms ease-in-out;
  }
`;
const WorshopItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  width: 100%;
  padding: 20px;
  border-top: 3px solid #2d3742;
  position: relative;
  @media screen and (min-width: 769px) {
    width: 100%;
    padding: 20px 20px 30px;
    border-top: 3px solid #2d3742;
    &:hover {
      background-color: #f4f5f7;
      ${ArrowIcon} {
        transform: translate(15px, 0);
      }
    }
  }
`;
const FormModal = styled(Modal)`
  display: block;
`;
const ModalContent = styled.div`
  color: #000;
  font-size: 14px;
  @media screen and (min-width: 769px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
`;
const Content = styled.div`
  position: relative;
  background: #f4f5f7;
  @media screen and (min-width: 769px) {
    width: 50%;
    display: block;
  }
`;
const DetailContent = styled.div`
  padding: 25px;
  @media screen and (min-width: 769px) {
    padding: 50px;
  }
`;
const Register = styled.div`
  padding: 25px;
  textarea:focus,
  input:focus,
  select:focus {
    outline: none;
  }
  color: #5e6c84;
  position: relative;
  button {
    background: #d22222;
    width: 200px;
    height: 50px;
    color: #fff;
    margin-top: 30px;
    cursor: pointer;
    outline: none;
  }
  label {
    font-size: 18px;
    color: #1b2733;
  }
  p {
    margin-bottom: 15px;
  }
  p:last-child {
    text-align: center;
  }
  input {
    border-radius: 4px;
    border: 1px solid #dfe1e6;
    background: #fff;
    width: 100%;
    height: 40px;
    background: #f4f5f7;
  }
  select {
    background: #f4f5f7;
  }
  @media screen and (min-width: 769px) {
    width: 50%;
    display: block;
    padding: 50px 30px 30px;
  }
`;
const FormHeading = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
`;
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  margin-left: -35px;
  background-color: ${({ theme }) => theme.brand};
  @media screen and (min-width: 769px) {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 20px;
  }
`;
const FormTitle = styled.div`
  font-size: 28px;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    color: #2d3742;
  }
`;
const CloseModal = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
const Seperator = styled.div`
  width: 175px;
  height: 4px;
  background: #2d3742;
  margin: 30px 0;
`;
const Detail = styled.div`
  color: #2d3742;
  @media screen and (min-width: 769px) {
    font-size: 24px;
    line-height: 31px;
  }
`;
const DetailBlock = styled.div`
  position: relative;
  @media screen and (min-width: 769px) {
    position: absolute;
    left: 50px;
    bottom: 50px;
  }
`;
const DotCircleLeft = styled.div`
  width: 10%;
  height: 232px;
  position: absolute;
  top: 3%;
  left: 0%;
  background-image: url(${haftCircle});
  background-size: 100%;
  background-repeat: no-repeat;
`;
const WSSharp = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 30px;
    height: 30px;
    background: red;
    position: absolute;
    top: 14%;
    left: 9%;
    border-radius: 15px;
    -webkit-animation: wsmove 10s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: wsmove 10s;
    animation-iteration-count: infinite;
    @-webkit-keyframes wsmove {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
      50% {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
      }
      75% {
        transform: scale(2);
        -webkit-transform: scale(2);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
    @keyframes wsmove {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
      50% {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
      }
      75% {
        transform: scale(2);
        -webkit-transform: scale(2);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
  }
`;
const SelectCustom = styled.select`
  border-radius: 4px;
  border: 1px solid #dfe1e6;
  background: #fff;
  width: 100%;
  height: 40px;
`;
const MessageBoard = styled.div`
  span {
    color: #d22222;
    display: block;
  }
`;
