import React from 'react';
import styled from 'styled-components';
import pt1 from '../../images/workshop/ptn_1.png';
import pt2 from '../../images/workshop/ptn_2.png';
import pt3 from '../../images/workshop/ptn_3.png';
import pt4 from '../../images/workshop/ptn_4.png';

const Partner = () => {
  return (
    <Wrapper>
      <ImgWrapper>
        <img src={pt1} alt="pt1" />
      </ImgWrapper>
      <ImgWrapper>
        <img src={pt2} alt="pt2" />
      </ImgWrapper>
      <ImgWrapper>
        <img src={pt3} alt="pt3" />
      </ImgWrapper>
      <ImgWrapper>
        <img src={pt4} alt="pt4" />
      </ImgWrapper>
    </Wrapper>
  );
};
export default Partner;
const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  display: flex;
  @media screen and (min-width: 769px) {
    padding: 60px 150px 0;
  }
`;
const ImgWrapper = styled.div`
  width: 25%;
  img {
    padding: 0 10px;
  }
  @media screen and (min-width: 769px) {
    width: 120px;
  }
`;
