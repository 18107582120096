import React from 'react';
import ReactModal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const modalStyle = {
  modal: {
    minWidth: 280,
    maxWidth: 1100,
    padding: 0,
    borerTopLeftRadius: 30,
    borerTopRightRadius: 30,
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

/**
 * @return {null}
 */
const Modal = ({ show, onClose, children }) => {
  return (
    <ReactModal
      open={show}
      onClose={onClose}
      showCloseIcon={false}
      styles={modalStyle}
      center
    >
      <ModalContent>{children}</ModalContent>
    </ReactModal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.shape({}).isRequired
};

Modal.defaultProps = {
  show: false
};

export default Modal;

const ModalContent = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  @media screen and (min-width: 769px) {
    width: 100%;
    height: auto;
  }
`;
