export const colorsV2 = {
  // Accent
  accent01_100: '#EF692F', // TODO: Remove this after change on UI design

  // Disabled
  grey30: '#DFE1E6', // TODO: Remove this after change on UI design
  grey70: '#5E6C84',
  grey100: '#242C34',

  // Black
  black100: '#242938',
  black80: '#505460',
  black60: '#7C7F88',
  black40: '#A7A9AF',
  black20: '#D3D4D7',
  black10: '#E9EAEB',
  black5: '#F4F4F5',

  blackShadow1: 'rgba(0, 0, 0, 0.01)',
  blackShadow2: 'rgba(0, 0, 0, 0.02)',
  blackShadow3: 'rgba(0, 0, 0, 0.03)',
  blackShadow4: 'rgba(0, 0, 0, 0.04)',
  blackShadow5: 'rgba(0, 0, 0, 0.05)',
  blackShadow6: 'rgba(0, 0, 0, 0.06)',

  // White
  white100: '#FFFFFF',
  white80: 'rgba(255, 255, 255, 0.8)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white5: 'rgba(255, 255, 255, 0.05)',

  // Gray
  gray120: '#6D7D8D',
  gray100: '#8F92A1',
  gray80: '#A5A8B4',
  gray60: '#BCBEC7',
  gray50: '#D5D7E1',
  gray40: '#D2D3D9',
  gray20: '#E9E9EC',
  gray10: '#F4F4F6',
  gray5: '#F9FAFA',

  // Light Gray
  lightGray100: '#F3F6F8',
  lightGray80: '#F5F8F9',
  lightGray60: '#F8FAFB',
  lightGray40: '#FAFBFC',
  lightGray20: '#FDFDFE',
  lightGray10: '#FEFEFE',
  lightGray5: '#FEFFFF',

  // Primary
  primary100: '#D14242',
  primary80: '#DA6868',
  primary60: '#E38E8E',
  primary40: '#EDB3B3',
  primary20: '#F6D9D9',
  primary10: '#FAECEC',
  primary5: '#FDF6F6',

  // Pink
  pink100: '#F8B6C3',
  pink80: '#F9C5CF',
  pink60: '#FBD3DB',
  pink40: '#FCE2E7',
  pink20: '#FEF0F3',
  pink10: '#FEF8F9',
  pink5: '#FFFBFC',

  // Yellow
  yellow120: '#dd7800',
  yellow100: '#FF991F',
  yellow80: '#FFAD4C',
  yellow60: '#FFC279',
  yellow40: '#FFD6A5',
  yellow20: '#FFEBD2',
  yellow10: '#FFF5E9',
  yellow5: '#FFFAF4',

  // Purple
  purple100: '#5243AA',
  purple80: '#7569BB',
  purple60: '#978ECC',
  purple40: '#BAB4DD',
  purple20: '#DCD9EE',
  purple10: '#EEECF7',
  purple5: '#F6F6FB',

  // Green
  green100: '#358756',
  green80: '#5D9F78',
  green60: '#86B79A',
  green40: '#AECFBB',
  green20: '#D7E7DD',
  green10: '#EBF3EE',
  green5: '#F5F9F7',

  // Red
  red100: '#DE350B',
  red80: '#E55D3C',
  red60: '#EB866D',
  red40: '#F2AE9D',
  red20: '#F8D7CE',
  red10: '#FCEBE7',
  red5: '#FDF5F3',

  // Blue
  blue100: '#2074BB',
  blue80: '#4D90C9',
  blue60: '#79ACD6',
  blue40: '#A6C7E4',
  blue20: '#D2E3F1',
  blue10: '#E9F1F8',
  blue5: '#F4F8FC',

  // Paper
  paper: '#FFF9F1',
  neutralPrimary: '#313A44'
};
