import styled, { ThemeProvider } from 'styled-components';

import Banner from '../../components/Workshop/Banner';
import Container from '../../components/Container';
import FormRegister from '../../components/Workshop/FormRegister';
import Partner from '../../components/Workshop/Partner';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '../../images/workshop/Slide.png';
import Speaker from '../../components/Workshop/Speaker';
import WorkShopComp from '../../components/Workshop/WorkShopComp';
import dotCircle from '../../images/workshop/dot_circle.png';
import { graphql } from 'gatsby';
import theme from '../../style/theme';
import withI18next from '../../components/withI18next';

const Workshop = ({ data }) => {
  const { wshopPages } = data.gcms;
  return (
    <ThemeProvider theme={theme}>
      <>
        <Container>
          <Banner video={wshopPages[0].video} />
          <Partner />
          <WorkShopComp workshops={wshopPages[0]} />
          <Speaker />
          <FormRegister wshopPages={wshopPages[0]} />
        </Container>
        <SquareCenter />
        <SlideSharp />
        <DotCircleRight />
        <SquareRight />
        <Circle />
        <SlideSharpRight />
        <Sharp />
        <Square />
      </>
    </ThemeProvider>
  );
};
Workshop.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.any
  })
};
export const query = graphql`
  query fetchWorkshop {
    gcms {
      wshopPages(stage: PUBLISHED) {
        wshops {
          id
          name
          detail
          time
          date
          level
        }
        video {
          ...CTACard
        }
      }
    }
  }
`;
export default withI18next()(Workshop);

const Sharp = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 30px;
    height: 30px;
    background: red;
    position: fixed;
    top: 5%;
    left: 5%;
    border-radius: 15px;
    -webkit-animation: mymove 10s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: mymove 10s;
    animation-iteration-count: infinite;
    @-webkit-keyframes mymove {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }
      50% {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: rotate3d(1, 1, 1, 180deg);
      }
      75% {
        transform: scale(2);
        -webkit-transform: scale(2);
        transform: rotate3d(1, 1, 1, 360deg);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }
    }

    @keyframes mymove {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }
      50% {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: rotate3d(1, 1, 1, 180deg);
      }
      75% {
        transform: scale(2);
        -webkit-transform: scale(2);
        transform: rotate3d(1, 1, 1, 360deg);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }
    }
  }
`;
const SlideSharp = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 30px;
    height: 30px;
    position: fixed;
    left: 1%;
    top: 50%;
    background-image: url(${Slide});
    background-size: 100%;
    -webkit-animation: circleleftmove 3s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: circleleftmove 3s;
    animation-iteration-count: infinite;
    @-webkit-keyframes circleleftmove {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes circleleftmove {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;
const DotCircleRight = styled.div`
display: none;
  @media screen and (min-width: 769px) {
  display: block;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 50%;
  right: 6%;
  background-image: url(${dotCircle});
  background-size: 100%;
  -webkit-animation: circlemove 20s; /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
  animation: circlemove 20s;
  animation-iteration-count: infinite;
  @-webkit-keyframes circlemove {
    0% {
      transform: rotate3d(1, 1, 1, 0deg);
    }
    50% {
      transform: rotate3d(1, 1, 1, 180deg);
    }
    100% {
      transform: rotate3d(1, 1, 1, 360deg);
    }
  }

  @keyframes circlemove {
    0% {
      transform: rotate3d(1, 1, 1, 0deg);
    }
    50% {
      transform: rotate3d(1, 1, 1, 180deg);
    }
    100% {
      transform: rotate3d(1, 1, 1, 360deg);
    }
  }
`;

const Circle = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid red;
    position: fixed;
    top: 70%;
    left: 2%;
    border-radius: 15px;
    -webkit-animation: myCircle 20s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: myCircle 20s;
    animation-iteration-count: infinite;
    @-webkit-keyframes myCircle {
      0% {
        top: 90%;
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }

      50% {
        top: 85%;
        transform: scale(2);
        -webkit-transform: scale(2);
        transform: rotate3d(1, 1, 1, 90deg);
      }
      100% {
        top: 90%;
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }
    }

    @keyframes myCircle {
      0% {
        top: 90%;
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }

      50% {
        top: 85%;
        transform: scale(2);
        -webkit-transform: scale(2);
        transform: rotate3d(1, 1, 1, 90deg);
      }
      100% {
        top: 90%;
        transform: scale(1);
        -webkit-transform: scale(1);
        transform: rotate3d(1, 1, 1, 0deg);
      }
    }
  }
`;
const Square = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid red;
    position: fixed;
    top: 50%;
    left: 11%;
    perspective: 500px;
    transform-style: preserve-3d;
    -webkit-animation: mySquare 20s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: mySquare 20s;
    animation-iteration-count: infinite;
    @-webkit-keyframes mySquare {
      0% {
        top: 50%;
        transform: rotate3d(1, 1, 1, 0deg);
      }
      50% {
        top: 55%;
        transform: rotate3d(1, 1, 1, 180deg);
      }
      100% {
        top: 50%;
        transform: rotate3d(1, 1, 1, 360deg);
      }
    }

    @keyframes mySquare {
      0% {
        top: 50%;
        transform: rotate3d(1, 1, 1, 0deg);
      }
      50% {
        top: 55%;
        transform: rotate3d(1, 1, 1, 180deg);
      }
      100% {
        top: 50%;
        transform: rotate3d(1, 1, 1, 360deg);
      }
    }
  }
`;

const SquareRight = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 50px;
    height: 50px;
    border: 2px solid red;
    position: fixed;
    top: 50%;
    right: 5%;
    perspective: 500px;
    transform-style: preserve-3d;
    -webkit-animation: SquareRight 20s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: SquareRight 20s;
    animation-iteration-count: infinite;
    @-webkit-keyframes SquareRight {
      0% {
        transform: rotate3d(1, 1, 1, 0deg);
      }
      50% {
        transform: rotate3d(1, 1, 1, 180deg);
      }
      75% {
        transform: rotate3d(1, 1, 1, 270deg);
      }
      100% {
        transform: rotate3d(1, 1, 1, 360deg);
      }
    }

    @keyframes SquareRight {
      0% {
        transform: rotate3d(1, 1, 1, 0deg);
      }
      50% {
        transform: rotate3d(1, 1, 1, 180deg);
      }
      75% {
        transform: rotate3d(1, 1, 1, 270deg);
      }
      100% {
        transform: rotate3d(1, 1, 1, 360deg);
      }
    }
  }
`;
const SquareCenter = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid red;
    position: fixed;
    top: 80%;
    right: 15%;
    -webkit-animation: SquareRight 20s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: SquareCenter 20s;
    animation-iteration-count: infinite;
    @-webkit-keyframes SquareCenter {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes SquareCenter {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;
const SlideSharpRight = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 1%;
    top: 5%;
    background-image: url(${Slide});
    background-size: 100%;
    -webkit-animation: SlideSharpRight 3s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation: SlideSharpRight 3s;
    animation-iteration-count: infinite;
    @-webkit-keyframes SlideSharpRight {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes SlideSharpRight {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;
