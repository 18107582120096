import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

import styled from 'styled-components';

import * as basedURL from '../../apis/baseURL';
import confirmIcon from '../../images/landing/redComfirmIcon.png';

const modalStyle = {
  modal: {
    minWidth: 320,
    padding: 0,
    borerTopLeftRadius: 30,
    borerTopRightRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    boxShadow: 'none'
  },
  overlay: {
    padding: '2em'
  }
};

class FormRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      telephone: '',
      email: '',
      facebook: '',
      subject: '',
      job: '',
      goal: '',
      problem: '',
      expectation: '',
      errorfullname: '',
      errortelephone: '',
      errorEmail: '',
      isShowModal: false,
      wshops: props.wshopPages
    };
  }

  handleOpenModal = () => {
    this.setState({ isShowModal: true });
  };

  handleCloseModal = () => {
    this.setState({ isShowModal: false });
  };

  formSubmitHandler = event => {
    event.preventDefault();
    const {
      fullname,
      telephone,
      email,
      facebook,
      subject,
      job,
      goal,
      problem,
      expectation
    } = this.state;

    if (fullname === '') {
      this.setState({ errorfullname: 'Yêu cầu nhập Họ và Tên' });
    } else {
      this.setState({ errorfullname: '' });
    }

    if (telephone === '') {
      this.setState({ errortelephone: 'Yêu cầu nhập số điện thoai' });
    } else {
      this.setState({ errortelephone: '' });
    }

    if (email === '') {
      this.setState({ errorEmail: 'Yêu cầu nhập Email' });
    } else {
      this.setState({ errorEmail: '' });
    }
    if (fullname !== '' && telephone !== '' && email !== '') {
      const frmData = {
        email,
        fullname,
        phone: telephone,
        facebook,
        subject,
        job,
        goal,
        problem,
        expectation,
        purpose: `Đăng ký tham gia ${subject}`
      };
      this.handleSendingData(JSON.stringify(frmData));
    }
  };

  handleSendingData = data => {
    fetch(`${basedURL.LMS}/storage`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: data
    }).then(response => {
      if (response.status === 200) this.handleOpenModal();
    });
  };

  formChangeHandler = event => {
    const nam = event.target.name;
    const val = event.target.value;
    this.setState({ [nam]: val });

    if (nam === 'fullname') {
      if (val === '') {
        this.setState({ errorfullname: 'Yêu cầu nhập Họ và Tên' });
      } else {
        this.setState({ errorfullname: '' });
      }
    }

    if (nam === 'telephone') {
      if (val === '') {
        this.setState({ errortelephone: 'Yêu cầu nhập số điện thoai' });
      } else {
        this.setState({ errortelephone: '' });
      }
    }

    if (nam === 'email') {
      if (val === '') {
        this.setState({ errorEmail: 'Yêu cầu nhập email' });
      } else {
        this.setState({ errorEmail: '' });
      }
    }
  };

  render() {
    const { errorfullname, errortelephone, errorEmail, isShowModal, wshops } =
      this.state;
    return (
      <>
        <SectionTitle>
          <Circle />
          Đăng ký tham gia
        </SectionTitle>
        <FormWrapper>
          <Modal
            open={isShowModal}
            onClose={() => {
              this.handleCloseModal();
            }}
            showCloseIcon
            styles={modalStyle}
            center
          >
            <ModalFormContent>
              <p>
                <img src={confirmIcon} alt="register" />
              </p>
              <p className="heading">DOL đã nhận được thông tin của bạn!</p>
              <p>
                Cám ơn bạn đã quan tâm, DOL sẽ liên hệ với bạn trong thời gian
                sớm nhất.
              </p>
            </ModalFormContent>
          </Modal>
          <form onSubmit={this.formSubmitHandler}>
            <MessageBoard>
              {errorfullname && <span>{errorfullname}</span>}
              {errortelephone && <span>{errortelephone}</span>}
              {errorEmail && <span>{errorEmail}</span>}
            </MessageBoard>
            <Col>
              <p>Họ và tên (*)</p>
              <p>
                <input
                  type="text"
                  name="fullname"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Số điện thoại (*)</p>
              <p>
                <input
                  type="text"
                  name="telephone"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Email (*)</p>
              <p>
                <input
                  type="text"
                  name="email"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Link Facebook cá nhân của bạn là ...</p>
              <p>
                <input
                  type="text"
                  name="facebook"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Bạn đăng ký tham dự chủ đề nào? (*)</p>
              <p>
                <SelectCustom name="subject" onChange={this.formChangeHandler}>
                  {wshops.wshops.map(ws => (
                    <option key={ws.name} value={ws.name}>
                      {ws.name}
                    </option>
                  ))}
                </SelectCustom>
              </p>
            </Col>
            <Col>
              <p>Bạn đang là?</p>
              <p>
                <textarea
                  rows="2"
                  name="job"
                  cols="50"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Mục đích học tiếng Anh của bạn?</p>
              <p>
                <textarea
                  rows="2"
                  name="purpose"
                  cols="50"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Những khó khăn hiện tại của bạn khi học tiếng Anh là gì?</p>
              <p>
                <textarea
                  rows="2"
                  name="problem"
                  cols="50"
                  onChange={this.formChangeHandler}
                />
              </p>
              <p>Bạn hy vọng sẽ đạt được điều gì sau buổi workshop này?</p>
              <p>
                <textarea
                  rows="3"
                  name="expectation"
                  cols="50"
                  onChange={this.formChangeHandler}
                />
              </p>
            </Col>
            <p>
              <button type="submit">Đăng ký</button>
            </p>
          </form>
        </FormWrapper>
      </>
    );
  }
}
export default FormRegister;
FormRegister.propTypes = {
  wshopPages: PropTypes.shape({}).isRequired
};
const ModalFormContent = styled.div`
  max-height: 300px;
  min-height: 240px;
  min-width: 500px;
  max-width: 100vw;
  overflow: auto;
  background: #fff;
  padding: 40px;
  text-align: center;
  img {
    width: 51px;
    height: 51px;
  }
  .heading {
    font-size: 20px;
    color: #d22222;
    font-weight: 700;
  }
`;
const MessageBoard = styled.div`
  span {
    color: #d22222;
    display: block;
  }
`;
const FormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: left;
  padding: 10px 0;
  form {
    width: 100%;
  }
  button {
    background: #d22222;
    width: 200px;
    height: 50px;
    color: #fff;
    margin-top: 30px;
    cursor: pointer;
    outline: none;
  }
  label {
    font-size: 18px;
    color: #5e6c84;
  }
  p {
    margin-bottom: 15px;
  }
  p:last-child {
    text-align: center;
  }
  input,
  select {
    border-radius: 4px;
    border: 1px solid #dfe1e6;
    background: #f4f5f7;
    width: 100%;
    height: 40px;
  }
  textarea {
    border-radius: 4px;
    border: 1px solid #dfe1e6;
    background: #f4f5f7;
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    width: 80%;
    display: flex;
    margin: 0 auto;
    position: relative;
    text-align: left;
    padding: 30px 0;
    label {
      font-size: 18px;
      color: #5e6c84;
    }
    p {
      margin-bottom: 15px;
    }
    p:last-child {
      text-align: center;
    }
    input {
      border-radius: 4px;
      border: 1px solid #dfe1e6;
      background: #f4f5f7;
      width: 100%;
      height: 40px;
    }
  }
`;
const Col = styled.div`
  width: 100%;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  @media screen and (min-width: 769px) {
    width: 50%;
  }
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
  display: flex;
  align-items: center;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    padding: 30px 170px;
  }
`;
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  margin-left: -35px;
  background-color: ${({ theme }) => theme.brand};
  @media screen and (min-width: 769px) {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 20px;
  }
`;
const SelectCustom = styled.select`
  border-radius: 4px;
  border: 1px solid #dfe1e6;
  background: #fff;
  width: 100%;
  height: 40px;
`;
