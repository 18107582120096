import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import WSLeftBg from '../../images/workshop/WSLeftBG.png';
import WSRightBG from '../../images/workshop/WSRightBG.png';

const Speaker = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          speakerOne: file(relativePath: { eq: "workshop/pic_1.png" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          speakerOneHover: file(
            relativePath: { eq: "workshop/pic_1_hover.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          speakerTwo: file(relativePath: { eq: "workshop/pic_2.png" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          speakerTwoHover: file(
            relativePath: { eq: "workshop/pic_2_hover.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          speakerThree: file(relativePath: { eq: "workshop/pic_3.png" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          speakerThreeHover: file(
            relativePath: { eq: "workshop/pic_3_hover.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <SpeakerWrapper>
          <SectionTitle>
            <Circle />
            Speaker
          </SectionTitle>
          <SpeakerRow>
            <ImaWrapper>
              <ImgCover
                fluid={data.speakerOne.childImageSharp.fluid}
                alt="childImageSharp"
              />
              <ImgCoverHover
                fluid={data.speakerOneHover.childImageSharp.fluid}
                alt="childImageSharp"
              />
              <FixedBG>
                <img src={WSLeftBg} alt="bg-img" />
              </FixedBG>
            </ImaWrapper>
            <SpeakerProfile>
              <SpeakerNameMobile>Nguyen Ba Tho</SpeakerNameMobile>
              <p>8.0 IELTS Overall</p>
              <p>Giảng viên Khoa Dược, ĐH Y Dược TPHCM</p>
              <p>Thạc sĩ Đại học Montpelier, Pháp</p>
              <p>Guest Speaker của Main Show the IELTS Face-off mùa 4</p>
            </SpeakerProfile>
            <SpeakerName>
              <div>Nguyen</div>
              <div>Ba Tho</div>
            </SpeakerName>
          </SpeakerRow>
          <SpeakerRow style={{ direction: 'rtl' }}>
            <ImaWrapper>
              <ImgCover
                fluid={data.speakerTwo.childImageSharp.fluid}
                alt="childImageSharp"
              />
              <ImgCoverHover
                fluid={data.speakerTwoHover.childImageSharp.fluid}
                alt="childImageSharp"
              />
              <FixedBGRight>
                <img src={WSRightBG} alt="bg-img" />
              </FixedBGRight>
            </ImaWrapper>
            <SpeakerProfile>
              <SpeakerNameMobile>Nguyen Canh Luan</SpeakerNameMobile>
              <p>Thủ khoa RMIT (GPA 4.0)</p>
              <p>8.0 IELTS Overall, 8.5 Speaking</p>
              <p>Cựu Chuyên Anh Lê Hồng Phong</p>
              <p>IELTS Musketeer của the IELTS Face-off mùa 4</p>
            </SpeakerProfile>
            <SpeakerNameRight>
              <div>Nguyen</div>
              <div>Canh Luan</div>
            </SpeakerNameRight>
          </SpeakerRow>
          <SpeakerRow>
            <ImaWrapper>
              <ImgCover
                fluid={data.speakerThree.childImageSharp.fluid}
                alt="childImageSharp"
              />
              <ImgCoverHover
                fluid={data.speakerThreeHover.childImageSharp.fluid}
                alt="childImageSharp"
              />
              <FixedBG>
                <img src={WSLeftBg} alt="bg-img" />
              </FixedBG>
            </ImaWrapper>
            <SpeakerProfile>
              <SpeakerNameMobile>Tran Thanh Giang</SpeakerNameMobile>
              <p>
                Thạc sĩ Giảng dạy ngôn ngữ Anh - Đại học Sussex - Vương quốc Anh
              </p>
              <p>8.0 IELTS Overall, 8.5 Speaking</p>
              <p>Cử nhân Truyền Thông, RMIT</p>
              <p>IELTS Musketeer của the IELTS Face-off mùa 4</p>
            </SpeakerProfile>
            <SpeakerName>
              <div>Tran</div>
              <div>Thanh Giang</div>
            </SpeakerName>
          </SpeakerRow>
          <hr />
        </SpeakerWrapper>
      )}
    />
  );
};
export default Speaker;

const SpeakerWrapper = styled.div`
  padding: 0 15px;
  @media screen and (min-width: 769px) {
    padding: 0 150px 50px;
    hr {
      margin-top: 50px;
    }
  }
`;
const SpeakerRow = styled.div`
  display: block;
  position: relative;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`;
const ImgCover = styled(Img)`
  display: block;
`;
const ImgCoverHover = styled(Img)`
  display: none;
`;
const ImaWrapper = styled.a`
  position: relative;
  cursor: pointer;
  display: block;
  &:hover {
    ${ImgCover} {
      display: none;
    }
    ${ImgCoverHover} {
      display: block;
    }
  }
  @media screen and (min-width: 769px) {
    width: 33%;
  }
`;
const SpeakerProfile = styled.div`
  padding: 20px;
  text-align: left;
  @media screen and (min-width: 769px) {
    margin-top: 18%;
  }
`;
const SpeakerName = styled.div`
  position: absolute;
  top: 15%;
  left: 33%;
  font-weight: 600;
  &::before {
    content: '';
    position: absolute;
    left: -70px;
    top: 22px;
    width: 40px;
    height: 4px;
    background: #2d3742;
  }
  display: none;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    display: block;
  }
`;
const SpeakerNameRight = styled.div`
  position: absolute;
  top: 15%;
  right: 30%;
  font-weight: 600;
  &::after {
    content: '';
    position: absolute;
    right: -52px;
    top: 22px;
    width: 40px;
    height: 4px;
    background: #2d3742;
  }
  display: none;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    display: block;
  }
`;
const SpeakerNameMobile = styled.div`
  font-weight: 600;
  display: block;
  font-size: 24px;
  margin-bottom: 15px;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    display: none;
  }
`;
const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
  display: flex;
  align-items: center;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    padding: 30px;
  }
`;
const FixedBG = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    position: absolute;
    right: -50px;
    bottom: -30px;
    display: block;
  }
`;
const FixedBGRight = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    position: absolute;
    right: -50px;
    bottom: -65px;
    display: block;
  }
`;
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  margin-left: -35px;
  background-color: ${({ theme }) => theme.brand};
  @media screen and (min-width: 769px) {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 20px;
  }
`;
