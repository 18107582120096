import styled, { css } from 'styled-components';

import { colorsV2 } from '../../style/colors-v2';

const Section = styled.section.attrs({ className: 'section' })`
  /* to increate specificity */
  &.pure.pure {
    padding-top: 0;
    padding-bottom: 0;
  }

  overflow: hidden;

  ${({ selfish }) =>
    selfish
      ? css`
          padding-top: 2em;
          padding-bottom: 2em;
        `
      : css`
          margin-top: 2em;
          margin-bottom: 2em;
        `}

  @media screen and (min-width: 1024px) {
    overflow: unset;

    ${({ selfish }) =>
      selfish
        ? css`
            padding-top: 4em;
            padding-bottom: 4em;
          `
        : css`
            margin-top: 4em;
            margin-bottom: 4em;
          `}
  }

  @media screen and (min-width: 768px) {
    overflow: unset;

    ${({ selfish }) =>
      selfish
        ? css`
            padding-top: 3em;
            padding-bottom: 3em;
          `
        : css`
            margin-top: 3em;
            margin-bottom: 3em;
          `}
  }
`;

/* breakpoints
  - majoR: 320, 720, 1024
  - minor: 600, 800, 1280
*/
const Container = styled.div.attrs({ className: 'container' })`
  /* there is always a gap between screen and content for both side */
  /* default is mobile */
  width: calc(100% - 48px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;

  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
    outline: none;
  }

  &.reading {
    max-width: 928px;
  }

  ${({ hasUnderline }) =>
    hasUnderline
      ? css`
          padding-bottom: 32px;
          border-bottom: 1px solid ${colorsV2.gray20};
        `
      : ''}

  @media screen and (min-width: 600px) {
    max-width: 600px;
  }
  @media screen and (min-width: 720px) {
    max-width: 720px;
  }
  @media screen and (min-width: 800px) {
    max-width: 800px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1024px;
    width: calc(100% - 80px);
  }
  @media screen and (min-width: 1140px) {
    max-width: 1120px;
    width: calc(100% - 40px);
  }
`;

const Wrapper = styled(Container)`
  @media screen and (min-width: 769px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 15px 0;
  }
`;

export { Section, Container, Wrapper };
