import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../images/workshop/logo.png';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  align-items: right;
  position: relative;
  @media screen and (min-width: 769px) {
    padding: 0 150px;
  }
`;

const ImgContainerDes = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 100%;
    height: auto;
    max-height: 677px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    video {
      width: 100%;
    }
  }
`;
const ImgContainerMobile = styled.div`
  display: block;
  width: 100%;
  height: auto;
  max-height: 677px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  video {
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const Banner = ({ video }) => {
  if (!video) return null;
  return (
    <Wrapper>
      <ImgContainerDes>
        {video.asset.wide.url && (
          <video autoPlay muted loop id="myVideo">
            <source src={video.asset.wide.url} type="video/mp4" />
          </video>
        )}
      </ImgContainerDes>
      <ImgContainerMobile>
        {video.asset.mobile.url && (
          <video autoPlay muted loop id="myVideo">
            <source src={video.asset.mobile.url} type="video/mp4" />
          </video>
        )}
      </ImgContainerMobile>
      <Logo />
      {video.title && <BannerText>{video.title}</BannerText>}
    </Wrapper>
  );
};

export default Banner;
Banner.propTypes = {
  video: PropTypes.shape({}).isRequired
};
Banner.propTypes = {
  video: PropTypes.shape({
    asset: PropTypes.shape({
      mobile: PropTypes.shape({
        url: PropTypes.any
      }),
      wide: PropTypes.shape({
        url: PropTypes.any
      })
    }),
    title: PropTypes.any
  })
};

const Logo = styled.div`
  background-image: url(${logo});
  width: 128px;
  height: 36px;
  background-size: 100%;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 769px) {
    top: 50px;
    left: 150px;
    right: auto;
  }
`;
const BannerText = styled.div`
  text-align: center;
  font-size: 5vw;
  position: absolute;
  z-index: 10;
  font-weight: 600;
  top: 45%;
  width: 70%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 769px) {
    font-size: 3.5vw;
    text-align: left;
    top: 35%;
    left: 150px;
    width: 50%;
    right: auto;
  }
`;
